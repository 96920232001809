import React from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import PreviewCompatibleImage from "../components/PreviewCompatibleImage";
import { Link } from "gatsby";
import { HTMLContent } from "../components/Content";

// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-cards";

// import required modules
import { EffectCards } from "swiper";

const Carousel = ({photos, link, title, description, closeModal })  => {
  return (
    <main className="carousel">
        <button className="close" onClick={closeModal}>X</button>
        <div className="swiper-container">
           <Swiper
                effect={"cards"}
                grabCursor={true}
                modules={[EffectCards]}
                className="swiper-container"
            >
                    {photos.map((photo, index) => (
                        <SwiperSlide key={index}>
                            <PreviewCompatibleImage imageInfo={photo}/>
                        </SwiperSlide>
                    ))}
            </Swiper>
            </div>
            <Link to={link} className="grid-view">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </Link>
            <div className="descrip">
                <div>{title}</div>
                <HTMLContent content={description}/>
            </div>
    </main>
  );
}

export default Carousel;




// .swiper-container 
//   width: 100%
//   height: 100%

// .swiper 
//   position: fixed
//   z-index: 100000000

// .swiper-slide 
//   height: 100%